<template>
  <vue-good-table
    :columns="columns"
    :rows="alerts"
    style-class="vgt-table alerts-table"
    max-height="calc(100vh - 135px)"
    :fixed-header="true"
    :search-options="{
      enabled: enabledSearch,
      externalQuery: search
    }"
    :sort-options="{
      enabled: true
    }"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <span
        v-if="props.column.label == $t('time')"
        class="td-date"
      >
        <b>{{ moment(props.row.time).format('ddd') }}</b>
        <span>{{ moment(props.row.time).format('MMM DD') }}</span>
        <b>{{ moment(props.row.time).format('hh:mm') }}</b>
        <span>{{ moment(props.row.time).format('a') }}</span>
      </span>
      <div
        v-else-if="props.column.label == $t('alert')"
        class="td-alert"
      >
        <general-suicidal-alert
          v-if="props.row.template === 1"
          :alert="props.row"
          @acknowledge="acknowledge"
        />
        <general-suicidal-alert-multiple-questions
          v-else-if="props.row.template === 2"
          :alert="props.row"
          @acknowledge="acknowledge"
        />
      </div>
      <span
        v-if="props.column.label == $t('clinician_s')"
        class="td-clinician"
      >
        <div
          v-for="(clinician, index) in props.row.clinicians"
          :key="index"
        >
          {{ clinician }}
        </div>
      </span>
    </template>
    <div slot="emptystate">
      <span
        v-if="hasAlerts && alertSelectedText != 'all'"
        class="no-alerts-message"
      >
        {{ $t('thereAreNo') }} <b>{{ $t(alertSelectedText).toLowerCase() }}</b> {{ $t('alerts') }}. <a
          href="javascript:void(0)"
          @click="$emit('changeAckSelector')"
        >{{ $t('viewAllAlerts') }}</a>.
      </span>
      <span v-else>
        {{ $t('thereAreNo') }} {{ $t('alerts') }}.
      </span>
    </div>
  </vue-good-table>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import generalSuicidalAlert from '@/components/common/measureAlertTemplates/generalSuicidalAlert'
import { ClinicianAlertTable } from '@/mixins/TableColumnsHelper'
import moment from 'moment'
import { USER_TYPE_SUPERVISOR } from '@/data/permissions'
import GeneralSuicidalAlertMultipleQuestions from '../common/measureAlertTemplates/generalSuicideAlertMultipleQuestions'
import { mapGetters, mapMutations } from 'vuex'
import { HIDE_CONTACT_INFO_DETAIL_FROM_ACKNOWLEDGMENT } from '@/store/modules/applicationState/constants'

export default {
  name: 'ClinicianPatientAlertTable',
  components: {
    GeneralSuicidalAlertMultipleQuestions,
    VueGoodTable,
    generalSuicidalAlert
  },
  mixins: [ClinicianAlertTable],
  props: ['alerts', 'hasAlerts', 'search'],
  data () {
    return {
      submitState: false,
      userTypeSupervisor: USER_TYPE_SUPERVISOR
    }
  },
  computed: {
    ...mapGetters({
      uiSettings: 'getUiSettings'
    }),
    alertSelectedText () {
      try {
        return this.uiSettings.alertsStatusSelector.selected
      } catch (error) {
        return ''
      }
    },
    enabledSearch () {
      return typeof this.search !== 'undefined'
    }
  },
  methods: {
    ...mapMutations({
      hideContactInformation: HIDE_CONTACT_INFO_DETAIL_FROM_ACKNOWLEDGMENT
    }),
    moment: (date) => {
      const dateUtc = moment.utc(date).toDate()
      return moment(dateUtc).local()
    },
    acknowledge (alert) {
      if (this.submitState) {
        return false
      }

      const message = this.$t('dischargeFinalizedMessage')
      this.submitState = true
      this.$store.dispatch('ACKNOWLEDGE_ALERT', alert).then((data) => {
        if (data && data.just_discharged === true) {
          this.$toast.success({ message })
        }
        this.$store.dispatch('GET_CLINICIAN_ALERTS_COUNT')
        this.submitState = false
        this.hideContactInformation({ alertId: alert.id })
      }).catch((e) => {
        this.$handleApiError(e)
      })
    }
  }
}
</script>
