<template>
  <div>
    <p
      class="general-suicidal-alert-title"
      :class="{ 'alert-title-acknowledged': alert.acknowledged_data.acknowledged }"
    >
      {{ $t('patientTrigeredAlert', { patient: alert.respondent_data.name, measure: alert.measure_name }) }}
    </p>
    <p
      class="question"
      v-html="alert.additional_params.endorsed_question_text"
    />
    <p
      class="answer"
      v-html="alert.additional_params.endorsed_question_answer_text"
    />
    <div class="alert-footer">
      <span
        v-if="alert.acknowledged_data.acknowledged"
        class="acnowledged"
      >
        {{ $t('acnowledged', { date: $toLocal(alert.acknowledged_data.datetime, 'MMM DD [at] hh:mm A'), clinician: clinicianName }) }}
      </span>
      <router-link
        :to="sessionReportRoute()"
        class="btn btn-outline-secondary"
      >
        {{ $t('measure_alert.view_all_responses') }}
      </router-link>
      <button
        v-if="!alert.acknowledged_data.acknowledged"
        class="btn btn-outline-secondary"
        type="button"
        @click="$emit('acknowledge', alert)"
      >
        {{ $t('acknowledge') }}
      </button>
      <button
        v-if="!alert.acknowledged_data.acknowledged"
        class="btn btn-outline-secondary"
        type="button"
        @click="contact"
      >
        {{ $t('contact') }}
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { Helpers } from '@/mixins/Helpers'
import { CONTACT_INFO_DETAIL } from '@/store/modules/applicationState/constants'
import { mapMutations } from 'vuex'

export default {
  name: 'GeneralSuicidalAlert',
  mixins: [DateTimeHelper, Helpers],
  props: ['alert'],
  computed: {
    clinicianName () {
      if (!this.alert.acknowledged_data.acknowledged) {
        return ''
      }

      const firstName = this.alert.acknowledged_data.acknowledged_user_first_name[0].toUpperCase() + this.alert.acknowledged_data.acknowledged_user_first_name.slice(1)
      const lastName = this.alert.acknowledged_data.acknowledged_user_last_name.slice(0, 1).toUpperCase()
      return firstName + ' ' + lastName
    }
  },
  methods: {
    ...mapMutations({
      setContactInfoDetail: CONTACT_INFO_DETAIL
    }),
    moment: (date) => {
      const dateUtc = moment.utc(date).toDate()
      return moment(dateUtc).local()
    },
    sessionReportRoute () {
      return {
        name: 'SessionDetail',
        params: {
          ccaId: this.$getEncodedId(this.alert.client_clinic_assignment_id),
          ccauId: this.$getEncodedId(this.alert.client_clinic_assignment_user_id),
          measureId: this.$getEncodedId(this.alert.client_measure_id)
        }
      }
    },
    contact () {
      this.setContactInfoDetail({
        ...this.alert.respondent_data,
        client_clinic_assignment_id: this.alert.client_clinic_assignment_id,
        client_clinic_assignment_user_id: this.alert.client_clinic_assignment_user_id
      })
    }
  }
}
</script>
