<template>
  <div class="patient-datail-contact">
    <div class="close-detail">
      <i
        class="fal fa-times"
        @click="$emit('close')"
      />
    </div>
    <div
      v-if="!hideUserInfo"
      class="detail-info"
    >
      <initials-circle
        :first="firstLetter"
        :second="secondLetter"
        class="initials"
        inverse
        first-id="navbar-outer-circle"
        second-id="navbar-inner-circle"
      />
      <div class="name">
        <label>{{ $t('patient') }}</label>
        <router-link
          v-if="ccaId && ccauId"
          :to="{ name: 'ClinicianPatientMeasures', params: {ccaId: ccaId, ccauId: ccauId} }"
        >
          {{ respondent.name }}
        </router-link>
        <span>{{ respondent.age }} {{ gender.charAt(0) }} <span class="mrn">{{ $t('mrn') }}:</span> {{ respondent.mrn }}</span>
      </div>
    </div>
    <div class="contact-info">
      <div
        v-if="showNotContactInfo"
        class="not-found"
      >
        <div>{{ $t('contactInfoNotFound') }}</div>
        <router-link
          v-if="ccaId && ccauId"
          :to="{ name: 'ClinicianPatientInformation', params: {ccaId: ccaId, ccauId: ccauId} }"
        >
          {{ $t('enterContactInfo') }}
        </router-link>
      </div>
      <div
        v-if="mobile_number"
        class="phone"
      >
        <label>{{ $t('cellToText') }}</label>
        <span>{{ mobile_number }}</span>
      </div>
      <div
        v-if="email_address"
        class="email"
      >
        <label>{{ $t('accEmail') }}</label>
        <a :href="'mailto:'+email_address">{{ email_address }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import InitialsCircle from '@/components/common/InitialsCircle'
import { Helpers } from '@/mixins/Helpers'
import { getPersonGenderLocaleLabel } from '@/mixins/persons/PersonGender'

export default {
  name: 'PatientDetailContact',
  components: { InitialsCircle },
  mixins: [Helpers],
  props: ['respondent', 'hideUserInfo'],
  computed: {
    ccauId () {
      return this.$getEncodedId(this.respondent.client_clinic_assignment_user_id)
    },
    ccaId () {
      return this.$getEncodedId(this.respondent.client_clinic_assignment_id)
    },
    showNotContactInfo () {
      return !this.mobile_number && !this.email_address
    },
    gender () {
      return this.$t(getPersonGenderLocaleLabel(this.respondent.gender))
    },
    firstLetter () {
      return this.respondent.first_name ? this.respondent.first_name[0] : ''
    },
    secondLetter () {
      return this.respondent.last_name ? this.respondent.last_name[0] : ''
    },
    mobile_number () {
      const contactInfo = this.respondent.contact_information.find(record => record.channel === 2)
      return contactInfo ? this.$maskedPhoneHyphens(contactInfo.value) : ''
    },
    email_address () {
      const contactInfo = this.respondent.contact_information.find(record => record.channel === 1)
      return contactInfo ? contactInfo.value : ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
