<template>
  <div
    v-if="!loading"
    class="patient-alert-list"
  >
    <clinician-patient-alert-table :alerts="alerts" />
    <div
      v-if="conctactInfoDetail"
      class="patient-detail"
    >
      <patient-detail-contact
        :hide-user-info="true"
        :respondent="conctactInfoDetail"
        @close="setContactInfoDetail(null)"
      />
    </div>
  </div>
</template>

<style>

</style>
<script>
import { mapGetters, mapMutations } from 'vuex'
import ClinicianPatientAlertTable from '@/components/clinician/ClinicianPatientAlertTable'
import PatientDetailContact from '@/components/clinician/patients/PatientDetailContact'
import { CONTACT_INFO_DETAIL } from '@/store/modules/applicationState/constants'

export default {
  name: 'ClinicianPatientAlerts',
  components: {
    ClinicianPatientAlertTable,
    PatientDetailContact
  },
  props: {},
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      alerts: 'getAlerts',
      conctactInfoDetail: 'getConctactInfoDetail',
      assignment: 'getActiveAssignment'
    })
  },
  created () {
    if (this.assignment.user_id) {
      const loader = this.$loading.show()
      this.loading = true
      this.$store.dispatch('GET_CLINICIAN_PATIENT_ALERTS', this.assignment.ccauId).then((response) => {
        if (response && response.status !== 200) {
          this.$handleApiError(response)
        }
      }).catch((e) => {
        this.$handleApiError(e)
      }).finally(() => {
        this.loading = false
        loader.hide()
      })
    }
  },
  destroyed () {
    this.setContactInfoDetail(null)
  },
  methods: {
    ...mapMutations({
      setContactInfoDetail: CONTACT_INFO_DETAIL
    })
  }
}
</script>
